<template>
  <div class="header-menu-userinfo">
    <div class="header-menu-userinfo-legacy">
      <div class="header-menu-userinfo-legacy-inner">
        <div>
          <a href="/mypage/profile">
            <img :src="user.avatar_image" class="header-menu-userinfo-icon" />
          </a>
        </div>
        <div class="header-menu-userinfo-namearea">
          <p class="header-menu-userinfo-name">{{ userName }} さん</p>
          <p v-if="!!registrations && registrations[0]" class="header-menu-userinfo-plan">
            {{ registrations[0].plan }}
          </p>
          <p v-if="!!registrations && registrations[0] && registrations[0].ticket" class="header-menu-userinfo-option">
            {{ registrations[0].ticket }}
          </p>
        </div>
      </div>
      <p v-if="!!registrations" class="header-menu-userinfo-guide">
        <a href="https://sites.google.com/address.love/popup/guide/ticket" target="_blank">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 28 28"
            fill="none"
            stroke="#CF761D"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            style="display: inline"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
            <line x1="12" y1="17" x2="12.01" y2="17"></line>
          </svg>
          チケットとは
        </a>
      </p>
    </div>
    <div v-for="(registration, index) in registrations" :key="registration.id">
      <div v-if="index === 0">
        <p v-if="!!registration.tickets.futsuno" class="header-menu-userinfo-legacyticket">
          ふつうのチケット残数
          <span v-if="!!!registration.tickets.futsuno.total && registration.tickets.futsuno.total !== 0" class="current">
            無制限
          </span>
          <span v-else>
            <span class="current">{{ registration.tickets.futsuno.unused }}</span>
            <span class="total">/ {{ registration.tickets.futsuno.total }}日</span>
          </span>
        </p>
        <p v-if="!!registration.tickets.dokodemo" class="header-menu-userinfo-legacyticket">
          どこでもチケット残数
          <span v-if="!!!registration.tickets.dokodemo.total" class="current">無制限</span>
          <span v-else>
            <span class="current">{{ registration.tickets.dokodemo.unused }}</span>
            <span class="total">/ {{ registration.tickets.dokodemo.total }}日</span>
          </span>
        </p>
      </div>
      <div v-else class="header-menu-userinfo-nextplan">
        <div class="header-menu-userinfo-nextplan-head">
          <p class="nextplan-day">{{ formatDate(registration.start_date) }}〜</p>
          <div class="nextplan-ttl">
            <p class="nextplan-name">{{ registration.plan }}</p>
            <p v-if="registration.ticket" class="nextplan-option">{{ registration.ticket }}</p>
          </div>
        </div>
        <p v-if="!!registration.tickets.futsuno" class="header-menu-userinfo-legacyticket">
          ふつうのチケット残数
          <span v-if="!!!registration.tickets.futsuno.total && registration.tickets.futsuno.total !== 0" class="current">
            無制限
          </span>
          <span v-else>
            <span class="current">{{ registration.tickets.futsuno.unused }}</span>
            <span class="total">/ {{ registration.tickets.futsuno.total }}日</span>
          </span>
        </p>
        <p v-if="!!registration.tickets.dokodemo" class="header-menu-userinfo-legacyticket">
          どこでもチケット残数
          <span v-if="!!!registration.tickets.dokodemo.total" class="current">無制限</span>
          <span v-else>
            <span class="current">{{ registration.tickets.dokodemo.unused }}</span>
            <span class="total">/ {{ registration.tickets.dokodemo.total }}日</span>
          </span>
        </p>
      </div>
    </div>
    <p v-if="shouldShowPlanLink()" class="header-menu-userinfo-offer">
      <a href="https://sites.google.com/address.love/userguide/home/plan?from=header" class="arrow-r-orange" target="_blank">
        プラン変更 / どこでもチケットオプションを検討されている方へ
      </a>
    </p>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import 'dayjs/locale/ja'

export default {
  props: {
    registrations: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    userName() {
      if (!!this.user && !!this.user.name) {
        return this.user.name
      }
      return '氏名：未登録'
    },
  },
  methods: {
    shouldShowPlanLink() {
      if (!this.registrations || !this.registrations[0] || !this.registrations[0].plan) {
        return false
      }
      let plan = this.registrations[0].plan
      if (plan === '法人プラン' || plan === '家守プラン' || plan === 'スタッフ') {
        return false
      }
      return true
    },
    formatDate(date) {
      return dayjs(date).format('M/D')
    },
  },
}
</script>
